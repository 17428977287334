nav {
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
}

nav ul {
  text-align: center;
  list-style: none;
  padding: 0px;
  margin: 0px;
}

nav ul li {
  position: relative;
  width: 70px;
  cursor: pointer;
  background: var(--secondary-light);
  text-transform: uppercase;
  transition: all 0.4s ease-out;
  font-size: 30px;
  font-weight: 700;
  font-family: "Anton";
  line-height: 1.1;
  letter-spacing: 3px;
}

nav ul li:after {
  position: absolute;
  background: dark;
  color: var(--secondary-light);
  top: 0;
  padding-left: 10px;
  padding-right: 10px;
  left: 70px;
  height: 100%;
  opacity: 0.5;
  transform: perspective(400px) rotateY(90deg);
  transform-origin: 0 100%;
  transition: all 0.4s ease-out;
  line-height: 88px;
  width: max-content;
}

nav ul li:nth-child(1):after {
  content: "Inicio";
}
nav ul li:nth-child(2):after {
  content: "Sobre mi";
}
nav ul li:nth-child(3):after {
  content: "Habilidades";
}
nav ul li:nth-child(4):after {
  content: "Servicios";
}
nav ul li:nth-child(5):after {
  content: "Contáctame";
}

nav ul li:hover {
  transform: translateX(-70px);
}

nav ul li:hover:after {
  opacity: 1;
  transform: perspective(400px) rotateY(0deg) scale(1);
}

nav ul .active {
  transform: translateX(-70px);
}
nav ul .active:after {
  opacity: 1;
  transform: perspective(400px) rotateY(0deg) scale(1);
}

nav ul li > div {
  display: inline-block;
  padding: 25px 0;
  background: transparent;
}

nav ul li div {
  position: relative;
}

@media (max-width: 768px) {
  nav {
    position: fixed;
    z-index: 1;
    bottom: 0 !important;
    top: auto;
    width: 100%;
  }

  nav ul {
    display: flex;
    width: 100%;
    background-color: var(--secondary-light);
    justify-content: space-around;
  }

  nav ul .active {
    background-color: white;
  }
  nav ul li::after {
    display: none;
  }
  nav ul li:hover:after {
    display: none;
  }
  nav ul li:hover {
    transform: none;
  }
  nav ul .active {
    transform: none;
  }

  nav ul li > div {
    display: inline-block;
    padding: 10px 0;
    background: transparent;
  }
}
@media (min-width: 992px) {
}
@media (min-width: 1200px) {
}
