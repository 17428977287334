.background-about {
  padding-top: 5%;
  padding-bottom: 5%;

}
.about {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
  height: fit-content;
  padding-bottom: 50px;
  border: 2px solid var(--secondary-light);
  padding: 20px;
  border-radius: 10px;
  margin-top: 30px;
  color: var(--dark);
}
.about .about-body {
  display: flex;
  justify-content: center;
  flex-direction: column;
  flex: 50%;

}
.about .about-body .content-text {
  display: flex;
  justify-content: center;
  gap: 6px;
  flex-direction: column;
}
.about .about-body .content-text p {
  font-weight: 800;
  text-align: center;
  padding: 16px;
  border-radius: 14px;
  box-shadow: 0px 2px 18px 0px rgba(0,0,0,0.3);
}

.about .about-body h2 {
  user-select: none;
  font-weight: 800;
  font-family: "Anton";
  font-size: 70px;
  margin: 0;
  letter-spacing: 4px;
  line-height: 1.1;
}

.about .about-body small {
  font-weight: 500;
  color: var(--primary);
}
.about .img-profile {
  border-radius: 50%;
  width: 44vh;
  height: 44vh;
  z-index: -1;
}
.about img {
  filter: drop-shadow(1px 1px 8px var(--dark));
  /*   border-bottom: 4px solid; */
  padding-bottom: 5px;
  border-radius: 5%;
  margin-right: 30px;
  border-image: linear-gradient(var(--secondary), var(--secondary-light)) 30;
}
.about .line {
  width: 100%;
  height: 1px;
  background-color: rgba(0, 0, 0, 0.4);
  margin-left: 10%;
  margin-right: 10%;
}

.buttons-body {
  display: flex;
  gap: 30px;
  margin-top: 30px;
}
.about .title{
  font-weight: 100;
  font-family: "Anton";
  font-size: 30px;
  margin: 0;
  border-bottom: 1px solid var(--secondary-light);
}

@media (max-width: 991px) {
  .about {
    flex-wrap: wrap-reverse;
    justify-content: center;
    height: fit-content;
    border: none;
    padding: 0px;
  }
}
@media (min-width: 992px) {
}
@media (min-width: 1200px) {
}
