.services {
  height: fit-content;
  color: var(--dark);
}

.work {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 20px;
  margin-top: 30px;
  margin-bottom: 30px;
}

.work .content {
  position: relative;
  overflow: hidden;
  border-radius: 10px;
  height: 176px;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
}
.work .item-work {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.work-list {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 20px;
  margin-top: 30px;
  margin-bottom: 30px;
}
.work-list .item-work .work-image {
  border-radius: 20px;
  width: 60%;
}
.work-list .item-work {
  display: flex;
  align-items: center;
}
.work-list .item-work .content {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 30px;
  flex-direction: column;
  width: 100%;
}
.work-list .item-work h3 {
  margin: 0;
}
.work-list .item-work .tecnologies {
  display: flex;
  gap: 10px;
}
.w-card {
  width: 30%;
  animation: fadeIn 0.5s linear;
  animation-fill-mode: both;
}

.work .content .content-overlay {
  background: rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(5px);
  position: absolute;
  height: 100%;
  width: 100%;
  opacity: 0;
  -webkit-transition: all 0.4s ease-in-out 0s;
  -moz-transition: all 0.4s ease-in-out 0s;
  transition: all 0.4s ease-in-out 0s;
}

.work .content:hover .content-overlay {
  opacity: 1;
}

.work .content-image {
  width: 100%;
}

.content-details {
  text-align: center;
  padding-left: 1em;
  padding-right: 1em;
  opacity: 0;
  width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  -webkit-transition: all 0.3s ease-in-out 0s;
  -moz-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s;
}
.icon-collapse {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  cursor: pointer;
  margin-right: 20px;
}

.content:hover .content-details {
  top: 50%;
  left: 50%;
  opacity: 1;
}

.content-details p {
  color: #fff;
  font-size: 13px;
  padding: 18px;
}

.content-name {
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: var(--dark);
}
.content-name div {
  display: flex;
  gap: 5px;
}
.fadeIn-left {
  left: 20%;
}

.fadeIn-bottom {
  top: 80%;
}

.fadeIn-top {
  top: 20%;
}

.fadeIn-left {
  left: 20%;
}

.fadeIn-right {
  left: 80%;
}

@media (max-width: 991px) {
  .w-card {
    width: 100%;
    height: fit-content;
  }
  .w-card .content {
    height: 300px;
    background-position: center;
  }
  .work-list .item-work .work-image {
    width: 100%;
  }
  .work-list .item-work {
    flex-wrap: wrap;
  }
}
@media (min-width: 992px) {
}
@media (min-width: 1200px) {
}
