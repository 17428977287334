.container {
  padding-right: 17px;
  padding-left: 17px;
  margin-right: auto;
  margin-left: auto;
  width: 80%;
}

.card {
  box-shadow: 1px 2px 14px 1px var(--gray-light);
  border-radius: 20px;
}
.float-bottom{
  font-family: "Anton";
  position: absolute;
  right: 5%;
  top: 20px;
  z-index: 1;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  color: var(--dark);
  user-select: none;
}
@media (max-width: 768px) {
  .container {
    width: 90%;
  }
}
