.background-home {
  padding-top: 5%;
}
.home {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
  padding-bottom: 50px;
}
.home .home-body {
  display: flex;
  justify-content: center;
  flex-direction: column;
  flex: 50%;
}
.home .home-body .content-text {
  display: flex;
  justify-content: center;
  gap: 6px;
  flex-direction: column;
}
.home .content-image {
  flex: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.home .home-body h2 {
  user-select: none;
  font-weight: 800;
  font-family: "Anton";
  font-size: 70px;
  margin: 0;
  letter-spacing: 4px;
  line-height: 1.1;
  color: var(--dark);
}
.typeWriteEffect {
  font-size: 2.25rem;
  font-weight: 700;
  margin: 0;
  color: var(--secondary-light);
}
.home .home-body small {
  font-weight: 500;
  color: var(--primary);
}
.home .img-profile {
  border-radius: 50%;
  width: 44vh;
  height: 44vh;
  background-color: var(--secondary-light);
  z-index: -1;
}
.home .img-profile::before {
  width: 100%;
  height: 100%;
  content: "";
  background: url(../../../assets/img/perfil.png);
  filter: drop-shadow(1px 1px 8px var(--dark));
  background-size: cover;
  display: block;
  border-radius: 0px 0 50% 50%;
}
.points {
  width: 500px;
  height: 500px;
}
.points::after {
  width: 40px;
  height: 40px;
  content: "";
  background-color: var(--dark);

  display: block;
  border-radius: 100%;
  bottom: 96%;
  left: 13px;
  z-index: -1;
}
.big-point {
  width: 25%;
  height: 25%;
  content: "";
  background-color: var(--dark);
  display: block;
  border-radius: 100%;
  position: absolute;
  bottom: 74%;
  z-index: -1;
  right: 23px;
}
.small-point {
  width: 10%;
  height: 10%;
  content: "";
  background-color: var(--dark);

  display: block;
  border-radius: 100%;
  position: absolute;
  z-index: -1;
  top: 24%;
  left: -40px;
}

.buttons-body {
  display: flex;
  gap: 30px;
  margin-top: 30px;
}
.buttons-body a{
  text-decoration: none;
}

.img-body {
  display: flex;
  gap: 5px;
  margin-top: 20px;
}
.img-body img {
  width: 40px;
  height: 24px;
  cursor: pointer;
  object-fit: contain;
  mix-blend-mode: color-burn;
}

@media (max-width: 991px) {
  .background-home {
    padding-top: 10%;
  }
  .home {
    flex-wrap: wrap-reverse;
    justify-content: center;
  }
  .home .content-image {
    margin-top: 20px;
    justify-content: center;
    width: 100%;
    flex: 100%;
  }
  .home .img-profile {
    width: 30vh;
    height: 30vh;
  }
  .home .content-text h2{
    font-size: 38px;
    margin-top: 30px;
  }
  .typeWriteEffect{
    font-size: 1.25rem;
  }
  .buttons-body{
    margin-top: 15px!important;
  }

}
@media (min-width: 992px) {
  .typeWriteEffect{
    font-size: 1.50rem;
  }
}
@media (min-width: 1200px) {
}
