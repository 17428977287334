.skills .content {
  display: flex;
  align-items: center;
  height: 60vh;
}

.skills .content .question {
  flex: 40%;
  gap: 20px;
  display: flex;
  flex-direction: column;
  color: var(--dark);
}
.skills .question p {
  font-weight: 100;
  font-family: "Anton";
  font-size: 30px;
  margin: 0;
  padding-left: 20px;
  border-left: 5px solid var(--secondary-light);
}
.skills .question small {
  /* text-align: justify; */
  margin: 0;
}
.skills .content .img-skills {
  flex: 20%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 20px;
}
.skills .content .img-skills img {
  width: 50px;
  height: 50px;
  padding: 20px;
  background-color:  var(--gray-light);
  border-radius: 30px;
  cursor: pointer;
}


@media (max-width: 991px) {
  .skills .question p{
    font-size: 25px;
  }
  .skills .content {
    flex-direction: column;
    height: fit-content;
  }
  .skills .content .question {
    flex: 100%;
    margin-top: 30px;
  }
  .skills .content .img-skills {
    flex: 100%;
    margin-top: 30px;
  }
}
@media (min-width: 992px) {
}
@media (min-width: 1200px) {
}
