.footer{
  background-color: var(--background);
  color: var(--dark)!important;
  border-top: 1px solid var(--gray-light);
  padding: 30px;
  text-align: center;
  color: var(--white);
  height: 5vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.footer h3{
 margin: 0;
}


@media (max-width: 991px) {
  .footer{
    margin-bottom: 54px;
  }
 }