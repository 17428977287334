:root {
  --primary: #30391f;
  --dark: black;
  --white: #ffffff;
  --secondary: #668a4c;
  --secondary-light: #accc7b;
  --background:white;
  --gray: #9d9c9c;
  --gray-light: #9d9c9c;


}

body {
  margin: 0;
  font-family: "Courier New", Courier, "Lucida Sans Typewriter",
    "Lucida Typewriter", monospace;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  background-color:  var(--background);;
}

@import "animate.css";

.text-secondary {
  color: var(--secondary);
}
.text-center {
  text-align: center;
}
.center {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.scroll-target {
  scroll-behavior: smooth;
}

::-webkit-scrollbar {
  width: 8px;
  background-color: #f5f5f5;
  border-radius: 50px;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px var(--primary);
  -webkit-box-shadow: inset 0 0 2px var(--primary);
  background-color: #f5f5f5;
}

::-webkit-scrollbar-thumb {
  background-color: var(--primary);
  border-radius: 50px;
}

.parpadea {
  animation-name: parpadeo;
  animation-duration: 1s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  -webkit-animation-name: parpadeo;
  -webkit-animation-duration: s;
  -webkit-animation-timing-function: linear;
  -webkit-animation-iteration-count: infinite;
}

.separation {
  margin-top: 20px;
  display: flex;
  align-items: flex-end;
  color: var(--gray);
}
.separation p {
  margin: 0;
  font-weight: 100;
  font-size: 18px;
  white-space: nowrap;
}

.separation .line {
  margin: 0px 0px 0px 10px;
  width: 100%;
  height: 0.08px;
  background-color: var(--gray);
}

@-moz-keyframes parpadeo {
  0% {
    display: 0;
  }

  100% {
    opacity: 1;
  }
}

@-webkit-keyframes parpadeo {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes parpadeo {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.btn-dark {
  background-color: black;
  color: white;
  padding: 10px 20px 10px 20px;
  font-size: 15px;
  border-radius: 0.5rem;
  box-shadow: none;
  border: 1px solid var(--primary);
  box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.08);
  font-weight: 700;
  cursor: pointer;
}
.btn-white {
  background-color: white;
  color: black;
  padding: 10px 20px 10px 20px;
  font-size: 15px;
  border-radius: 0.5rem;
  box-shadow: none;
  border: transparent;
  font-weight: 700;
  box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.08);
  cursor: pointer;
}
