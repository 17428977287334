.contact {
  height: 70vh;
}
.contact {
  display: flex;
  justify-content: center;
  align-items: center;
}

.contact .text {
  width: 50%;
  color: var(--dark);
}
.contact .form {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 10px;
  padding: 40px;
}
.contact .form span {
  color: var(--dark);
  opacity: 0.6;
  font-weight: 700;
  user-select: none;
}
input,
textarea {
  font-family: "Courier New", Courier, "Lucida Sans Typewriter",
    "Lucida Typewriter", monospace;
  border-radius: 5px;
  border: 1px solid var(--secondary-light);
  box-shadow: 1px 0px 3px 0px var(--secondary-light);
  font-size: 12px;
  color: rgba(0, 0, 0, 0.7);
  padding: 10px;
  user-select: none;
}
input::placeholder,
textarea::placeholder {
  color: rgba(0, 0, 0, 0.4);
  font-size: 12px;
}
input,
textarea:focus-visible {
  outline: none;
}
.form button {
  background-color: var(--secondary-light);
  border: 1px solid var(--secondary-light);
  padding: 10px;
  font-size: 15px;
  font-weight: 700;
  border-radius: 10px;
  cursor: pointer;
  color: var(--white);
}
.form button:hover {
  color: var(--secondary);
  box-shadow: 1px 0px 3px 0px var(--secondary-light);
}
.text-danger{
  color: red!important;
  font-size: 13px;
}
.contact .text h2 {
  font-weight: 100;
  font-family: "Anton";
  font-size: 30px;
  margin: 0;
}
.contact p {
  margin: 0;
}
@media (max-width: 991px) {
  .contact {
    flex-direction: column;
    width: 100%;
    height: fit-content;
  }
  .contact .text {
    margin-top: 30px;
    width: 100%;
  }
  .contact .form {
    margin-top: 20px;
    width: 100%;
    padding: 0px;
    margin-bottom: 20px;
  }
}
@media (min-width: 992px) {
}
@media (min-width: 1200px) {
}
